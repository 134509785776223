<!--
 * @Descripttion: 
 * @version: 
 * @Author: 王超
 * @Date: 2021-09-01 00:47:01
 * @LastEditors: 王超
 * @semesterEnd: 2021-09-01 00:47:01
-->
<template>
  <div class="foundation_page">
    <div style="font-size: 16px; color: #ba3037; padding: 30px 0 20px 0">
      成绩只需填写数字，如测试项目为视力，左右眼的两个成绩需用 /
      分隔，填写所有测试成绩提交后则不可修改成绩
    </div>
    <div class="custTab">
      <table class="layui-table" lay-skin="nob">
        <thead>
          <tr>
            <th>幼儿编号</th>
            <th>姓名</th>
            <th>性别</th>
            <th>{{ title1 }}</th>
            <th>{{ title2 }}</th>
            <th>{{ title3 }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in list.data" :key="item.id">
            <td class="">
              S{{ common.PrefixZero(index + 1 + (params.page - 1) * 10, 4) }}
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.sex }}</td>
            <td>
              <div
                v-if="showDouble"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <input
                  class="inpsty"
                  v-model.trim="item.values.reportOneLeft"
                  :disabled="
                    item.status == null
                      ? false
                      : item.status == 0
                      ? false
                      : true
                  "
                  maxlength="10"
                  placeholder="左脚"
                  @blur="
                    handleDoubleInput(
                      item,
                      index,
                      item.values.reportOneLeft,
                      '1'
                    )
                  "
                  oninput="value=value.replace(/[^\d^\.]+/g,'')"
                />
                <input
                  class="inpsty"
                  v-model.trim="item.values.reportOneRight"
                  :disabled="
                    item.status == null
                      ? false
                      : item.status == 0
                      ? false
                      : true
                  "
                  maxlength="10"
                  placeholder="右脚"
                  @blur="
                    handleDoubleInput(
                      item,
                      index,
                      item.values.reportOneRight,
                      '1'
                    )
                  "
                  oninput="value=value.replace(/[^\d^\.]+/g,'')"
                />
              </div>
              <input
                v-else
                class="inpsty"
                v-model.trim="item.values['1']"
                :disabled="
                  item.status == null ? false : item.status == 0 ? false : true
                "
                maxlength="10"
                @blur="handleInput(item, index, item.values['1'], '1')"
                oninput="value=value.replace(/[^\d^\.]+/g,'')"
              />
            </td>
            <td>
              <div
                v-if="showDouble"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <input
                  class="inpsty"
                  v-model.trim="item.values.reportTwoLeft"
                  :disabled="
                    item.status == null
                      ? false
                      : item.status == 0
                      ? false
                      : true
                  "
                  maxlength="10"
                  placeholder="左脚"
                  @blur="
                    handleDoubleInput(
                      item,
                      index,
                      item.values.reportTwoLeft,
                      '2'
                    )
                  "
                  oninput="value=value.replace(/[^\d^\.]+/g,'')"
                />
                <input
                  class="inpsty"
                  v-model.trim="item.values.reportTwoRight"
                  :disabled="
                    item.status == null
                      ? false
                      : item.status == 0
                      ? false
                      : true
                  "
                  maxlength="10"
                  placeholder="右脚"
                  @blur="
                    handleDoubleInput(
                      item,
                      index,
                      item.values.reportTwoRight,
                      '2'
                    )
                  "
                  oninput="value=value.replace(/[^\d^\.]+/g,'')"
                />
                <!-- <input v-if="index == (list.data.length - 1)" style="width:0;height:0;opacity:0;" /> -->
              </div>
              <input
                v-else
                class="inpsty"
                v-model.trim="item.values['2']"
                :disabled="
                  item.status == null ? false : item.status == 0 ? false : true
                "
                maxlength="10"
                @blur="handleInput(item, index, item.values['2'], '2')"
                oninput="value=value.replace(/[^\d^\.]+/g,'')"
              />
              <input
                v-if="index == list.data.length - 1"
                style="display: block; width: 0; height: 0; opacity: 0"
              />
            </td>
            <td>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                v-if="showDouble"
              >
                <div class="zong">
                  {{ item.values.reportThreeLeft }}
                </div>
                <div class="zong">
                  {{ item.values.reportThreeRight }}
                </div>
              </div>
              <div v-else class="zong">
                {{ item.values["3"] }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <com-pagination :params="params" @changePage="changePage"></com-pagination>
    <!-- <div class="loadmore loadmore-line loadmore-nodata">
      <span class="loadmore-tips">暂无数据</span>
    </div> -->
    <!-- <div class="pages">{$bt->render()}</div> -->
    <div class="startc" style="margin-bottom: 20px">
      <!-- <div class="btn btn_start tab" @click="submit()">完成</div> -->
      <el-button
        type="primary"
        class="btn btn_start"
        :loading="btnLoding"
        @click="submit"
        >完成</el-button
      >
    </div>
  </div>
</template>

<script>
import comPagination from "@/components/admin/pagination.vue";
export default {
  name: "atte_sports",
  components: { comPagination },
  data() {
    return {
      btnLoding: false,
      list: [
        {
          testingLog: {
            1: "",
            2: "",
            3: "",
          },
        },
      ],
      params: {
        page: 0,
        size: 10,
        total: 0,
      },
      title1: "一测成绩(精确到0.1秒)",
      title2: "二测成绩(精确到0.1秒)",
      title3: "最终成绩(精确到0.1秒)",
      sportId: 0,
      showDouble: false,
      cacheName: "",
    };
  },
  created() {},
  mounted() {
    this.sportId = this.$route.query.sportId;
    this.cacheName = `sportTest_schoolId=${this.$store.state.userInfo.schoolId}_teacherId=${this.$store.state.userInfo.teacherId}_testingSportsId=${this.$route.query.sportId}_isEnd=${this.$route.query.isEnd}_gradeId=${this.$route.query.gradeId}`;
    if (this.sportId == 22 || this.sportId == 21 || this.sportId == 7) {
      this.title1 = "一测成绩(精确到1个)";
      this.title2 = "二测成绩(精确到1个)";
      this.title3 = "最终成绩(精确到1个)";
    }
    if (this.sportId == 4 || this.sportId == 15 || this.sportId == 16) {
      this.showDouble = true;
    }
    this.getList();
  },
  methods: {
    // 获取运动测试表格列表
    getList() {
      let data = {
        gradeId: this.$route.query.gradeId,
        gradeType: this.$route.query.gradeType,
        isEnd: this.$route.query.isEnd,
        province: this.$store.state.schoolInfo.atteSchool.province,
        schoolId: this.$store.state.userInfo.schoolId,
        semesterId: this.$store.state.userInfo.semesterId,
        teacherId: this.$route.query.teacherId,
        testingSportsId: this.$route.query.sportId,
        testlevel: this.$route.query.testlevel,
        type: "1",
        stuIds: this.$route.query.stuIds,
      };
      this.api.pes
        .findStuSportTestDataByGradeId(this.params, data)
        .then((res) => {
          this.list = res.data.list[0];
          //处理缓存数据
          this.formatCacheData();
          this.list.data.forEach((item) => {
            item.values = item.values ? item.values : {};
            if (this.sportId == 22 || this.sportId == 21 || this.sportId == 7) {
              item.values["1"] = !item.values["1"] ? "" : item.values["1"];
              item.values["2"] = !item.values["2"] ? "" : item.values["2"];
              item.values["3"] = !item.values["3"] ? "" : item.values["3"];
            } else {
              item.values["1"] =
                item.values["1"] == "0" || !item.values["1"]
                  ? ""
                  : item.values["1"];
              item.values["2"] =
                item.values["2"] == "0" || !item.values["2"]
                  ? ""
                  : item.values["2"];
              item.values["3"] =
                item.values["3"] == "0" || !item.values["3"]
                  ? ""
                  : item.values["3"];
            }

            if (this.showDouble) {
              if (item.values["1"] && item.values["1"].indexOf("/") != -1) {
                let a1 = item.values["1"].split("/")[0];
                let a2 = item.values["1"].split("/")[1];
                item.values.reportOneLeft = a1;
                item.values.reportOneRight = a2;
              } else {
                item.values.reportOneLeft = item.values["1"];
                item.values.reportOneRight = item.values["1"];
              }
              if (item.values["2"] && item.values["2"].indexOf("/") != -1) {
                let a1 = item.values["2"].split("/")[0];
                let a2 = item.values["2"].split("/")[1];
                item.values.reportTwoLeft = a1;
                item.values.reportTwoRight = a2;
              } else {
                item.values.reportTwoLeft = item.values["2"];
                item.values.reportTwoRight = item.values["2"];
              }
              if (item.values["3"] && item.values["3"].indexOf("/") != -1) {
                let a1 = item.values["3"].split("/")[0];
                let a2 = item.values["3"].split("/")[1];
                item.values.reportThreeLeft = a1;
                item.values.reportThreeRight = a2;
              } else {
                item.values.reportThreeLeft = item.values["3"];
                item.values.reportThreeRight = item.values["3"];
              }
            }
          });
          this.params.total = res.data.total;
        });
    },
    toTest() {
      this.$router.push({
        path: "/testCheck",
        query: {
          gradeId: this.$route.query.id,
          isSports: false,
        },
      });
    },
    changePage(val) {
      this.params.page = val;
      this.getList();
    },
    handleInput(item, index, val, key) {
      let num1 = 0;
      let num2 = 0;
      // 超级快递,跨越小溪,超级出租,跨越石块,超级飞侠,跳跃石子
      if (
        this.sportId == 1 ||
        this.sportId == 2 ||
        this.sportId == 9 ||
        this.sportId == 11 ||
        this.sportId == 10 ||
        this.sportId == 12
      ) {
        num1 = 4;
        num2 = 60;
        //穿越森林,螃蟹历险,翻山越岭
      } else if (
        this.sportId == 3 ||
        this.sportId == 13 ||
        this.sportId == 14
      ) {
        num1 = 6;
        num2 = 90;
        //鳄鱼上岸,搬家公司,小熊捕鱼,邮递公司,小猴抓桃
      } else if (
        this.sportId == 5 ||
        this.sportId == 28 ||
        this.sportId == 17 ||
        this.sportId == 29 ||
        this.sportId == 18
      ) {
        num1 = 5;
        num2 = 90;
        //水果收纳,水果搬运（中班）,水果运输
      } else if (
        this.sportId == 6 ||
        this.sportId == 19 ||
        this.sportId == 20
      ) {
        num1 = 5;
        num2 = 120;
        //珍贵鸡蛋,鸡蛋进篮（中班）
      } else if (this.sportId == 7 || this.sportId == 21) {
        num1 = 0;
        num2 = 10;
        //小鸡回家
      } else if (this.sportId == 22) {
        num1 = 0;
        num2 = 9;
        //快速取件
      } else if (this.sportId == 30) {
        num1 = 10;
        num2 = 180;
      }
      if (item.values["1"]) {
        let judge = true;
        let fIndex = item.values["1"].indexOf(".");
        let indexs = item.values["1"].split(".");
        if (fIndex == 0) {
          judge = false;
        } else if (fIndex > 0 && fIndex == item.values["1"].length - 1) {
          judge = false;
        } else if (indexs.length > 2) {
          judge = false;
        }
        if (this.sportId == 22 || this.sportId == 21 || this.sportId == 7) {
          if (fIndex > 0) {
            judge = false;
          }
        }
        if (fIndex > 0 && item.values["1"].length - fIndex > 2) {
          item.values["1"] = item.values["1"].substring(0, fIndex + 2);
        }
        if (
          parseFloat(item.values["1"]) < num1 ||
          parseFloat(item.values["1"]) > num2
        ) {
          judge = false;
        }
        if (!judge) {
          item.values["1"] = "";
          this.$confirm("超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
      }
      if (item.values["2"]) {
        let judge = true;
        let fIndex = item.values["2"].indexOf(".");
        let indexs = item.values["2"].split(".");
        if (fIndex == 0) {
          judge = false;
        } else if (fIndex > 0 && fIndex == item.values["2"].length - 1) {
          judge = false;
        } else if (indexs.length > 2) {
          judge = false;
        }
        if (this.sportId == 22 || this.sportId == 21 || this.sportId == 7) {
          if (fIndex > 0) {
            judge = false;
          }
        }
        if (fIndex > 0 && item.values["2"].length - fIndex > 2) {
          item.values["2"] = item.values["2"].substring(0, fIndex + 2);
        }
        if (
          parseFloat(item.values["2"]) < num1 ||
          parseFloat(item.values["2"]) > num2
        ) {
          judge = false;
        }
        if (!judge) {
          item.values["2"] = "";
          this.$confirm("超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
      }
      if (item.values["1"] && item.values["2"]) {
        let a1 = item.values["1"];
        let a2 = item.values["2"];
        if (this.sportId == 7 || this.sportId == 21 || this.sportId == 22) {
          item.values["3"] =
            parseFloat(a1) < parseFloat(a2)
              ? item.values["2"]
              : item.values["1"];
        } else {
          item.values["3"] =
            parseFloat(a1) < parseFloat(a2)
              ? item.values["1"]
              : item.values["2"];
        }
      } else {
        item.values["3"] = "";
      }

      if (item.values["1"] || item.values["2"]) {
        this.cacheData(item, index, item.values[key], key);
      }

      // if (val.reportOne || val.reportTwo) {
      //   let data = {
      //     type: "1",
      //     stuId: parseInt(item.atteStudent.id),
      //     teacherId: this.$route.query.teacherId,
      //     testingSportsId: parseInt(this.$route.query.sportId),
      //     testlevel: this.$route.query.testlevel,
      //     semesterId: parseInt(this.$route.query.semesterId),
      //     gradeId: parseInt(item.atteStudent.gradeId),
      //     isEnd: this.$route.query.isEnd,
      //     gradeType: this.$route.query.gradeType,
      //     schoolId: parseInt(this.$route.query.schoolId),
      //     province: this.$store.state.userInfo.provinces,
      //     data: {
      //       1: val.reportOne,
      //       2: val.reportTwo,
      //       3: val.reportThree,
      //     },
      //   };
      //   if (val.canPost) {
      //     val.canPost = false;
      //     this.api.pes.add(data).then((res) => {
      //       val.canPost = true;
      //       if (!res.flag) {
      //         val.reportOne = "";
      //         val.reportTwo = "";
      //         val.reportThree = "";
      //         this.$confirm("数据上传失败，请重试", "提示", {
      //           confirmButtonText: "确定",
      //           cancelButtonText: "取消",
      //           type: "warning",
      //         });
      //       }
      //     });
      //   } else {
      //     this.$confirm("数据正在上传中，请稍后再试", "提示", {
      //       confirmButtonText: "确定",
      //       cancelButtonText: "取消",
      //       type: "warning",
      //     });
      //   }
      // }
    },
    //缓存数据
    cacheData(item, index, val, key) {
      let exerciseTest = JSON.parse(localStorage.getItem(this.cacheName)) || {};
      if (exerciseTest.data) {
        if (exerciseTest.data[`page${this.params.page}`]) {
          //到page这级
          let flag = false;
          let curInd = 0;
          for (
            let i = 0;
            i < exerciseTest.data[`page${this.params.page}`].length;
            i++
          ) {
            if (
              exerciseTest.data[`page${this.params.page}`][i].stuId ===
              item.stuId
            ) {
              flag = true;
              curInd = i;
              break;
            }
          }
          if (flag) {
            //有对应的stuId 到values这级
            exerciseTest.data[`page${this.params.page}`][curInd].values[key] =
              val;
            exerciseTest.data[`page${this.params.page}`][curInd].values["3"] =
              item.values["3"];
            if (!val) {
              if (
                !exerciseTest.data[`page${this.params.page}`][curInd].values[
                  "1"
                ] &&
                !exerciseTest.data[`page${this.params.page}`][curInd].values[
                  "2"
                ]
              ) {
                this.$delete(
                  exerciseTest.data[`page${this.params.page}`],
                  curInd
                );
              }
            }
          } else {
            //没有对应的stuId
            let param = {
              stuId: item.stuId,
              status: item.status,
            };
            param.values = {};
            param.values[key] = val;
            exerciseTest.data[`page${this.params.page}`].push(param);
          }
        } else {
          let param = {
            stuId: item.stuId,
            status: item.status,
          };
          param.values = {};
          param.values[key] = val;
          if (!exerciseTest.data[`page${this.params.page}`]) {
            exerciseTest.data[`page${this.params.page}`] = [];
          }
          exerciseTest.data[`page${this.params.page}`].push(param);
        }
      } else {
        exerciseTest = {
          teacherId: this.$store.state.userInfo.teacherId,
          schoolId: this.$store.state.userInfo.schoolId,
          testingSportsId: this.$route.query.sportId,
          data: {},
        };
        exerciseTest.data[`page${this.params.page}`] = [];
        let param = {
          stuId: item.stuId,
          status: item.status,
        };
        param.values = {};
        param.values[key] = val;
        exerciseTest.data[`page${this.params.page}`].push(param);
      }
      localStorage.setItem(this.cacheName, JSON.stringify(exerciseTest));
    },
    //处理缓存数据
    formatCacheData() {
      let exerciseTest = JSON.parse(localStorage.getItem(this.cacheName)) || {};
      if (
        exerciseTest.teacherId == this.$store.state.userInfo.teacherId &&
        exerciseTest.schoolId == this.$store.state.userInfo.schoolId
      ) {
        if (exerciseTest.data[`page${this.params.page}`]) {
          this.list.data.forEach((sItem) => {
            if (sItem.status === "0") {
              let cacheData = exerciseTest.data[
                `page${this.params.page}`
              ].filter((ssItem) => {
                return ssItem.stuId === sItem.stuId;
              })[0];
              if (cacheData) {
                sItem.values = JSON.parse(JSON.stringify(cacheData.values));
              }
            }
          });
        }
      }
    },
    handleDoubleInput(item, index, val, key) {
      let num1 = 0;
      let num2 = 0;
      if (this.sportId == 4) {
        num1 = 2;
        num2 = 70;
        //隐形火箭
      } else if (this.sportId == 15) {
        num1 = 2;
        num2 = 80;
        // 运送卫星
      } else if (this.sportId == 16) {
        num1 = 2;
        num2 = 180;
      }
      if (item.values.reportOneLeft) {
        let judge = true;
        let fIndex = item.values.reportOneLeft.indexOf(".");
        let indexs = item.values.reportOneLeft.split(".");
        if (fIndex == 0) {
          judge = false;
        } else if (
          fIndex > 0 &&
          fIndex == item.values.reportOneLeft.length - 1
        ) {
          judge = false;
        } else if (indexs.length > 2) {
          judge = false;
        }
        if (fIndex > 0 && item.values.reportOneLeft.length - fIndex > 2) {
          item.values.reportOneLeft = item.values.reportOneLeft.substring(
            0,
            fIndex + 2
          );
        }
        if (
          parseFloat(item.values.reportOneLeft) < num1 ||
          parseFloat(item.values.reportOneLeft) > num2
        ) {
          judge = false;
        }
        if (!judge) {
          item.values.reportOneLeft = "";
          this.$confirm("超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
      }
      if (item.values.reportOneRight) {
        let judge = true;
        let fIndex = item.values.reportOneRight.indexOf(".");
        let indexs = item.values.reportOneRight.split(".");
        if (fIndex == 0) {
          judge = false;
        } else if (
          fIndex > 0 &&
          fIndex == item.values.reportOneRight.length - 1
        ) {
          judge = false;
        } else if (indexs.length > 2) {
          judge = false;
        }
        if (fIndex > 0 && item.values.reportOneRight.length - fIndex > 2) {
          item.values.reportOneRight = item.values.reportOneRight.substring(
            0,
            fIndex + 2
          );
        }
        if (
          parseFloat(item.values.reportOneRight) < num1 ||
          parseFloat(item.values.reportOneRight) > num2
        ) {
          judge = false;
        }
        if (!judge) {
          item.values.reportOneRight = "";
          this.$confirm("超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
      }
      if (item.values.reportTwoLeft) {
        let judge = true;
        let fIndex = item.values.reportTwoLeft.indexOf(".");
        let indexs = item.values.reportTwoLeft.split(".");
        if (fIndex == 0) {
          judge = false;
        } else if (
          fIndex > 0 &&
          fIndex == item.values.reportTwoLeft.length - 1
        ) {
          judge = false;
        } else if (indexs.length > 2) {
          judge = false;
        }
        if (fIndex > 0 && item.values.reportTwoLeft.length - fIndex > 2) {
          item.values.reportTwoLeft = item.values.reportTwoLeft.substring(
            0,
            fIndex + 2
          );
        }
        if (
          parseFloat(item.values.reportTwoLeft) < num1 ||
          parseFloat(item.values.reportTwoLeft) > num2
        ) {
          judge = false;
        }
        if (!judge) {
          item.values.reportTwoLeft = "";
          this.$confirm("超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
      }
      if (item.values.reportTwoRight) {
        let judge = true;
        let fIndex = item.values.reportTwoRight.indexOf(".");
        let indexs = item.values.reportTwoRight.split(".");
        if (fIndex == 0) {
          judge = false;
        } else if (
          fIndex > 0 &&
          fIndex == item.values.reportTwoRight.length - 1
        ) {
          judge = false;
        } else if (indexs.length > 2) {
          judge = false;
        }
        if (fIndex > 0 && item.values.reportTwoRight.length - fIndex > 2) {
          item.values.reportTwoRight = item.values.reportTwoRight.substring(
            0,
            fIndex + 2
          );
        }
        if (
          parseFloat(item.values.reportTwoRight) < num1 ||
          parseFloat(item.values.reportTwoRight) > num2
        ) {
          judge = false;
        }
        if (!judge) {
          item.values.reportTwoRight = "";
          this.$confirm("超出测试范围", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
          return;
        }
      }
      if (item.values.reportOneLeft && item.values.reportOneRight) {
        item.values["1"] =
          item.values.reportOneLeft + "/" + item.values.reportOneRight;
      } else {
        item.values["1"] = "";
      }
      if (item.values.reportTwoLeft && item.values.reportTwoRight) {
        item.values["2"] =
          item.values.reportTwoLeft + "/" + item.values.reportTwoRight;
      } else {
        item.values["2"] = "";
      }
      if (item.values["1"] && item.values["2"]) {
        let a1 = item.values.reportOneLeft;
        let a2 = item.values.reportTwoLeft;
        item.values.reportThreeLeft = parseFloat(a1) < parseFloat(a2) ? a2 : a1;
        let b1 = item.values.reportOneRight;
        let b2 = item.values.reportTwoRight;
        item.values.reportThreeRight =
          parseFloat(b1) < parseFloat(b2) ? b2 : b1;
      } else {
        item.values.reportThreeLeft = "";
        item.values.reportThreeRight = "";
      }
      if (item.values.reportThreeLeft && item.values.reportThreeRight) {
        item.values["3"] =
          item.values.reportThreeLeft + "/" + item.values.reportThreeRight;
      } else {
        item.values["3"] = "";
      }
      this.cacheData(item, index, item.values[key], key);
      // if (val.reportOne || val.reportTwo) {
      //   let data = {
      //     type: "1",
      //     stuId: parseInt(item.atteStudent.id),
      //     teacherId: this.$route.query.teacherId,
      //     testingSportsId: parseInt(this.$route.query.sportId),
      //     testlevel: this.$route.query.testlevel,
      //     semesterId: parseInt(this.$route.query.semesterId),
      //     gradeId: parseInt(item.atteStudent.gradeId),
      //     isEnd: this.$route.query.isEnd,
      //     gradeType: this.$route.query.gradeType,
      //     schoolId: parseInt(this.$route.query.schoolId),
      //     province: this.$store.state.userInfo.provinces,
      //     data: {
      //       1: val.reportOne,
      //       2: val.reportTwo,
      //       3: val.reportThree,
      //     },
      //   };
      //   if (val.canPost) {
      //     val.canPost = false;
      //     this.api.pes.add(data).then((res) => {
      //       val.canPost = true;
      //       if (!res.flag) {
      //         val.reportOne = "";
      //         val.reportTwo = "";
      //         val.reportThree = "";
      //         this.$confirm("数据上传失败，请重试", "提示", {
      //           confirmButtonText: "确定",
      //           cancelButtonText: "取消",
      //           type: "warning",
      //         });
      //       }
      //     });
      //   } else {
      //     this.$confirm("数据正在上传中，请稍后再试", "提示", {
      //       confirmButtonText: "确定",
      //       cancelButtonText: "取消",
      //       type: "warning",
      //     });
      //   }
      // }
    },
    submit() {
      this.btnLoding = true;
      let exerciseTest = JSON.parse(localStorage.getItem(this.cacheName)) || {};
      for (let name in exerciseTest.data) {
        for (let i = 0; i < exerciseTest.data[name].length; i++) {
          if (exerciseTest.data[name][i].status === "0") {
            console.log(
              exerciseTest.data[name][i].values["3"],
              'exerciseTest.data[name][i].values["3"]'
            );
            if (!exerciseTest.data[name][i].values["3"]) {
              this.params.page = name.split("page")[1];
              this.$notice({
                type: "warning",
                message: "请提交完整数据",
              }).isShow();
              this.getList();
              this.btnLoding = false;
              return;
            }
          }
        }
      }
      let list = [];
      for (let name in exerciseTest.data) {
        list = list.concat(exerciseTest.data[name]);
      }
      if (!list.length) {
        this.$notice({
          type: "warning",
          message: "您已添加数据请勿重复提交",
        }).isShow();
        this.btnLoding = false;
        return;
      }
      let data = {
        gradeId: Number(this.$route.query.gradeId),
        gradeType: this.$route.query.gradeType,
        isEnd: this.$route.query.isEnd,
        province: this.$store.state.schoolInfo.atteSchool.province,
        schoolId: Number(this.$store.state.userInfo.schoolId),
        semesterId: Number(this.$store.state.userInfo.semesterId),
        teacherId: this.$route.query.teacherId,
        testingSportsId: Number(this.$route.query.sportId),
        testlevel: this.$route.query.testlevel,
        type: "1",
        data: list,
      };
      console.log(data);
      this.api.pes.addSportsData(data).then((res) => {
        if (res.flag) {
          this.$notice({
            type: "success",
            message: res.message,
          }).isShow();
          this.getList();
          localStorage.removeItem(this.cacheName);
          this.btnLoding = false;
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
          this.btnLoding = false;
        }
      });
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/* 火狐浏览器样式清除 */
input[type="number"] {
  -moz-appearance: textfield;
}
.foundation_page {
  width: 100%;
  .custTab thead th,
  .custTab tbody td {
    text-align: center;
    font-size: 0.24rem !important;
  }
  .inpsty {
    width: 54px;
    height: 22px;
    padding: 0;
    line-height: 22px;
    font-size: 14px;
    text-align: center;
    background: #ba30371c;
    border-radius: 4px;
  }

  .startc {
    padding-right: 0.5rem;
    margin-bottom: 2rem;
  }

  .startc .btn {
    width: 100px;
    height: 38px;
    background: linear-gradient(232deg, #b93037, #ef525b);
    box-shadow: 0px 3px 6px 0px rgba(255, 67, 77, 0.33);
    border-radius: 6px;
    cursor: pointer;
    font-size: 18px;
    color: #fff;
    line-height: 38px;
    text-align: center;
    border: none;
  }
  .startc .btn:hover {
    background: linear-gradient(232deg, #cf565b, #f46e73);
  }
  .tabTit {
    min-height: 0.9rem;
    box-sizing: border-box;
    /* text-align: right; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .tabTit .tips {
    font-size: 0.2rem;
    /* position: absolute;
    left: 0; */
  }

  .tabTit .text {
    line-height: 0.3rem;
    font-weight: 700;
    color: #cb1b31;
    font-size: 0.3rem;
    /* padding-right: .5rem;
    text-align: right;
    float: right; */
  }

  .tabTit .searchdiv {
    display: flex;
  }

  .tabTit .searchdiv .btn-search {
    margin-right: 0.2rem;
  }

  .tabTit .btn-add,
  .tabTit .btn-blue {
    padding: 0rem 0.4rem;
    height: 38px;
    line-height: 38px;
    font-size: 0.22rem;
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle;
    border: none;
  }

  .tabTit .btn-add {
    background: #cb1b31;
    color: #fff;
  }

  .tabTit .btn-blue {
    background: #228cec;
    color: #fff;
  }
  .custTab {
    /* padding: 0rem .5rem; */
    position: relative;
  }

  .custTab .layui-table {
    padding: 0;
    margin: 0;
    width: 100%;
    table-layout: fixed;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
    border-radius: 4px;
  }

  .custTab thead {
    position: relative;
  }

  .custTab thead th {
    font-size: 16px !important;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    opacity: 0.9;
    background: #ba3037;
    height: 42px;
    padding: 0;
  }
  .custTab .layui-table tr th:first-child {
    border-radius: 4px 0 0 0;
  }
  .custTab .layui-table tr th:last-child {
    border-radius: 0 4px 0 0;
  }
  .custTab tbody tr:last-child {
    border-bottom: none;
  }

  .custTab tbody tr {
    border-bottom: solid 1px #dddddd;
  }

  .custTab tbody td {
    height: 42px;
    font-size: 14px !important;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    opacity: 0.9;
    padding: 0;
  }

  .custTab tbody tr td:last-child {
    padding-right: 0;
  }

  .custTab .btn-start {
    width: 1.28rem;
    height: 0.38rem;
    line-height: 0.38rem;
    background: #b5b5b5;
    color: #fff;
    display: inline-block;
    font-size: 0.22rem;
    text-align: center;
    transition: all 0.2s;
    float: right;
  }
  .custTab .btn-start:hover {
    background: #cc1a30;
  }

  .custTab .btn-play {
    // background: #cc1a30 url(../images/play2.png) 0.2rem center no-repeat;
    background-size: 0.26rem;
    height: 0.4rem;
    line-height: 0.4rem;
    padding-left: 0.6rem;
    width: 0.7rem;
    display: inline-block;
    border-radius: 0.25rem;
    color: #fff;
    font-size: 0.22rem;
  }
}
.dialog_block {
  display: flex;
  justify-content: center;
}
.zong {
  padding-right: 10px;
}
</style>
